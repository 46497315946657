/* eslint-disable no-dupe-keys */

// import { useParams } from "react-router-dom";
import Emojify from 'react-emojione';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import axios, * as others from 'axios';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

// const axios = require('axios');

function ChatScreen() {
  const navigate = useNavigate();

  // let { id } = useParams()

  const styles = useStyles();
  const userId = JSON.parse(localStorage.getItem('userDetails'));

  const [chatList, setChatList] = useState([]);

  useEffect(() => {
    getUserChat();
  }, []);

  const getUserChat = () => {
    const Admintoken = JSON.parse(localStorage.getItem('adminDetails'));
    const token = Admintoken.admin_id_token;
    const id = localStorage.getItem('chat_id');

    const session = localStorage.getItem('session');

    const data = {
      admin_id_token: token,
      user_id: userId.user_id,
      session,
    };
    // console.log('object is', data);

    try {
      axios
        .post(`https://chatbot.digitallandscape.com.pk:5024/get_user_conversation`, data)
        .then((response) => {
          // console.log(response);
          if (response?.status === 200) {
            if (response.data?.is_valid_token === true) {
              setChatList(response.data?.data.user_chat);
            } else {
              localStorage.setItem('adminDetails', ' ');
              // navigate('../LoginScreen')
              navigate(`/login`);
            }
          } else {
            // alert("Error hai")
            navigate(`/sessions`);
          }
        })
        .catch((error) => {
          // // console.log(error);
          // alert(error.message)
          navigate(`../sessions`);
        });
    } catch (error) {
      // console.log('Error : ', error);
      alert(JSON.stringify(error));
    }
  };

  const listItems = chatList.map((item, index) => (
    <li key={index} className={styles.listItem}>
      {/* {JSON.stringify(item)} */}
      <div className={styles.mainChatItemContainer}>
        <div className={styles.mainChatClientItemContainer}>
          {/* <h6 className={styles.userLabelHeader}>User</h6> */}
          {!item?.user.includes(':') ? (
            <h6 className={styles.userLabel}>{item?.user}</h6>
          ) : (
            <Emojify style={{ height: '50%', width: '50%' }}>
              <span className={styles.userLabel}>{item.user}</span>
            </Emojify>
          )}
          {/* <h6 className={styles.userTimeLabel}>{item?.user_time}</h6> */}
          {/* {item?.user_time} */}
        </div>
        <h6 className={styles.userTimeLabel}>{item?.user_time}</h6>

        <div className={styles.mainChatBotItemContainer}>
          {/* <h6 className={styles.botLabelHeader}>Bot</h6> */}
          <h6 className={styles.botLabel}>{item?.bot}</h6>
          {/* <h6 className={styles.botTimeLabel}>{item?.user_time}</h6> */}
          {/* {item?.bot_time} */}
        </div>
        <h6 className={styles.botTimeLabel}>{item?.user_time}</h6>
      </div>
    </li>
  ));

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chat
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/sessions"
            startIcon={<Iconify icon="akar-icons:arrow-back-thick" />}
          >
            Back to Session
          </Button>
        </Stack>
        <Stack>
          <div className={styles.mainContainer}>
            <ul className={styles.list}>{listItems}</ul>
          </div>
        </Stack>
      </Container>
    </Page>
  );
}

const useStyles = createUseStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  header: {
    fontSize: '4vh',
    marginLeft: '2vw',
  },
  list: {
    width: '100%',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: '30px',
  },
  listItem: {
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: 10,
    padding: 0,
    cursor: 'pointer',

    // webkitBoxShadow: "1px 4px 4px #00000050",
    // mozBoxShadow: "1px 4px 4px #00000050",
    // boxShadow: "1px 4px 4px #00000050",
  },
  mainChatItemContainer: {
    // width: '90%',
    // backgroundColor: '#429EFA50',
    // borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    // margin: '1vh',
    fontSize: '1.5vh',
    borderWidth: '1px',
    // borderColor: '#00000050',
    // borderStyle: 'groove',
    // borderRadius: 10,
    // padding: '1vh'
  },
  mainChatBotItemContainer: {
    // backgroundColor: '#429EFA',
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    color: '#FFFFFF',
    padding: '1vh',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    marginTop: '10px',
    marginRight: '50%',
    width: '70%',
    background: '#67B3FF 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 12px #0000001F',
    borderRadius: '28px 28px 28px 0px',
    opacity: 1,
  },
  mainChatClientItemContainer: {
    backgroundColor: '#FFFFFF',
    // borderBottomLeftRadius: 10,
    // borderBottomRightRadius: 10,

    width: 'auto',
    padding: '1vh',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
    marginTop: '10px',
    marginLeft: '50%',
    paddingLeft: '5px',
    float: 'right',
    background: '#67B3FF 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #0000001F',
    borderRadius: '28px 28px 0px 28px',
    opacity: 1,
    //     width:"30%",
    // //     // minWidth:"50%",
    //      maxWidth:"100%"
  },
  userLabelHeader: {
    width: '8vh',
    minWidth: '3vh',
    color: 'green',
    fontSize: '14pt',
    fontWeight: 'bold',
    margin: '1vh',
    padding: '1vh',
    borderColor: 'green',
    borderStyle: 'groove',
    borderRadius: 10,
    borderWidth: '1px',
    // padding: '1vh',
    textAlign: 'center',
  },
  userLabel: {
    flex: 1,

    // color: 'black',
    fontSize: '11pt',
    // fontStyle:'italic',
    margin: '1vh',
    padding: '1vh',
  },
  userTimeLabel: {
    // color: 'black',
    // fontSize: '12pt',
    // fontStyle:'italic',
    // margin: '1vh',
    // padding: '1vh',
    color: 'black',
    fontSize: '10pt',
    fontWeight: 'normal',
    margin: '1vh',
    // fontSize: '14pt',
    fontStyle: 'italic',
    padding: '1vh',
    marginLeft: '50%',
    paddingLeft: '5px',
    float: 'right',
  },
  botLabel: {
    flex: 1,
    // color: 'black',
    color: '#FFFFFF',
    // fontSize: '1.5vh',
    // fontWeight: 'normal',
    fontSize: '11pt',
    // fontWeight:'bold',
    // fontStyle:'italic',
    margin: '1vh',
    padding: '1vh',
  },
  botTimeLabel: {
    color: 'black',
    fontSize: '10pt',
    fontWeight: 'normal',
    margin: '1vh',
    // fontSize: '14pt',
    fontStyle: 'italic',
    padding: '1vh',
  },
  botLabelHeader: {
    width: '8vh',
    minWidth: '3vh',
    color: 'red',
    // fontSize: '1.5vh',
    // fontWeight: 'normal',
    fontSize: '14pt',
    fontWeight: 'bold',
    margin: '1vh',
    padding: '1vh',
    borderColor: 'red',
    borderStyle: 'groove',
    borderRadius: 10,
    borderWidth: '1px',
    // eslint-disable-next-line no-dupe-keys
    padding: '1vh',
    textAlign: 'center',
  },
});

export default ChatScreen;
